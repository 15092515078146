import { getDistanceFromLonLat } from '../../helpers/exclusives/map';

const CURRENT_MARKETS = ['austin', 'houston', 'dallas'];
const NEW_MARKETS = ['charlotte', 'raleigh'];

export const MARKETS = [...CURRENT_MARKETS, ...NEW_MARKETS];

export type MarketLocation = (typeof MARKETS)[number];

export const DEFAULT_MARKET: MarketLocation = 'dallas';
export const DEFAULT_MARKET_LOCAL_STORAGE_KEY = '@exclusives/DEFAULT_MARKER';

export function getPersistedMarket(): MarketLocation | null {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(DEFAULT_MARKET_LOCAL_STORAGE_KEY) as MarketLocation | null;
}

export function getDefaultMarket(): MarketLocation {
  return getPersistedMarket() || DEFAULT_MARKET;
}

export function setDefaultMarket(market: MarketLocation) {
  if (typeof localStorage === 'undefined') throw new Error("This can't be done in server-side");
  localStorage.setItem(DEFAULT_MARKET_LOCAL_STORAGE_KEY, market);
}

export function getNearestMarket(lonLat: [number, number]): MarketLocation {
  let nearestMarket: MarketLocation = MARKETS[0];
  const [, ...markets] = MARKETS;
  let distance = getDistanceFromLonLat(lonLat, MARKET_LON_LAT[nearestMarket]);
  for (const market of markets) {
    const marketDistance = getDistanceFromLonLat(lonLat, MARKET_LON_LAT[market]);
    if (distance > marketDistance) {
      distance = marketDistance;
      nearestMarket = market;
    }
  }
  return nearestMarket;
}

// Temporary solution to make getNearestMarket work properly that doesn't rely on IP
// Longer term, we should have Cloudflare-worker pass us the market.
export function getNearestMarketByCityState(city: string, state: string): MarketLocation {
  if (state != 'TX') {
    return DEFAULT_MARKET;
  }
  for (const market of MARKETS) {
    if (MARKET_CITIES[market].includes(city)) {
      return market;
    }
  }
  return DEFAULT_MARKET;
}

export const MARKET_LON_LAT: Record<MarketLocation, [number, number]> = {
  austin: [-97.73333, 30.266666],
  houston: [-95.358421, 29.749907],
  dallas: [-96.80492258951422, 32.715376892680624],
  charlotte: [-80.843126, 35.227085],
  raleigh: [-78.638179, 35.779591],
};

export const MARKET_EMAIL_IDENTIFIERS: Record<MarketLocation, string> = {
  austin: 'atx',
  houston: 'hou',
  dallas: 'dfw',
  charlotte: 'clt',
  raleigh: 'rdu',
};

export const MARKET_EMAIL_IDENTIFIERS_TO_MARKET_ID: Record<string, MarketLocation> =
  Object.fromEntries(
    (Object.entries(MARKET_EMAIL_IDENTIFIERS) as [MarketLocation, string][]).map(
      ([marketId, marketEmailId]) => [marketEmailId, marketId],
    ),
  );

export const MARKET_TEXT: Record<MarketLocation, string> = {
  austin: 'Austin',
  dallas: 'Dallas–Fort Worth',
  houston: 'Houston',
  charlotte: 'Charlotte',
  raleigh: 'Raleigh',
};

export const STATE: Record<MarketLocation, string> = {
  austin: 'TX',
  dallas: 'TX',
  houston: 'TX',
  charlotte: 'NC',
  raleigh: 'NC',
};

export const MARKET_SHORT_LABELS: Record<MarketLocation, string> = {
  austin: 'Austin',
  dallas: 'DFW',
  houston: 'Houston',
  charlotte: 'Charlotte',
  raleigh: 'Raleigh',
};

export const MARKET_CITIES: Record<MarketLocation, string[]> = {
  austin: [
    'Austin',
    'Round Rock',
    'Pflugerville',
    'Leander',
    'Georgetown',
    'Cedar Park',
    'Kyle',
    'Hutto',
    'Buda',
    'Manor',
    'Jarrell',
    'San Marcos',
    'Lago Vista',
    'Bastrop',
    'Elgin',
    'Liberty Hill',
    'Taylor',
    'Del Valle',
    'Dripping Springs',
    'Lockhart',
    'Manchaca',
    'Wimberley',
    'Lakeway',
    'Spicewood',
    'Jonestown',
    'Cedar Creek',
    'Paige',
    'Bertram',
    'Bee Cave',
    'Marble Falls',
    'The Hills',
    'Blanco',
    'Smithville',
    'Andice',
  ],
  houston: [
    'Houston',
    'Spring',
    'Katy',
    'Humble',
    'Richmond',
    'Cypress',
    'Missouri City',
    'Pearland',
    'Baytown',
    'Tomball',
    'Sugar Land',
    'Kingwood',
    'League City',
    'Conroe',
    'Rosenberg',
    'Crosby',
    'Porter',
    'Fresno',
    'La Porte',
    'Friendswood',
    'Pasadena',
    'Hockley',
    'Rosharon',
    'Magnolia',
    'Deer Park',
    'Texas City',
    'Manvel',
    'Stafford',
    'Seabrook',
    'Dickinson',
    'Channelview',
    'Montgomery',
    'Angleton',
    'La Marque',
    'Brookshire',
    'Webster',
    'Alvin',
    'Willis',
    'New Caney',
    'Meadows Place',
    'Highlands',
    'Jersey Village',
    'Fulshear',
    'Galveston',
    'Dayton',
    'Pinehurst',
    'Cove',
    'Huffman',
    'Splendora',
    'West Columbia',
    'Danbury',
    'Bacliff',
    'South Houston',
    'Hitchcock',
    'Santa Fe',
    'Cleveland',
    'Sealy',
    'Hempstead',
    'El Lago',
    'Galena Park',
    'Jamaica Beach',
    'Old River Winfree',
    'The Woodlands',
    'Beach City',
    'Shenandoah',
    'Liberty',
  ],
  dallas: [
    'Fort Worth',
    'Arlington',
    'Dallas',
    'McKinney',
    'Garland',
    'Grand Prairie',
    'Forney',
    'Plano',
    'Mesquite',
    'Little Elm',
    'Rowlett',
    'Burleson',
    'Denton',
    'Carrollton',
    'Lewisville',
    'Frisco',
    'Wylie',
    'North Richland Hills',
    'Mansfield',
    'Aubrey',
    'Crowley',
    'Desoto',
    'Irving',
    'Allen',
    'Haslet',
    'Cedar Hill',
    'Anna',
    'Rockwall',
    'Lancaster',
    'The Colony',
    'Bedford',
    'Richardson',
    'Euless',
    'Royse City',
    'Roanoke',
    'Saginaw',
    'Watauga',
    'Flower Mound',
    'Fate',
    'Princeton',
    'Hurst',
    'Duncanville',
    'Midlothian',
    'Keller',
    'Corinth',
    'Justin',
    'Red Oak',
    'Waxahachie',
    'Benbrook',
    'Azle',
    'Haltom City',
    'Heartland',
    'Sachse',
    'Grapevine',
    'Glenn Heights',
    'Providence Village',
    'Savannah',
    'Argyle',
    'Coppell',
    'Crossroads',
    'Balch Springs',
    'Weatherford',
    'Prosper',
    'Melissa',
    'Lake Dallas',
    'Cleburne',
    'Celina',
    'Aledo',
    'Lavon',
    'Sanger',
    'Murphy',
    'Lantana',
    'Granbury',
    'Crandall',
    'Venus',
    'Krum',
    'Seagoville',
    'Rhome',
    'Greenville',
    'Forest Hill',
    'Addison',
    'Springtown',
    'Ennis',
    'Kennedale',
    'River Oaks',
    'Alvarado',
    'Colleyville',
    'Decatur',
    'Willow Park',
    'Godley',
    'Joshua',
    'Sunnyvale',
    'Newark',
    'Pantego',
    'Ferris',
    'Krugerville',
    'Van Alstyne',
    'Richland Hills',
    'Caddo Mills',
    'Farmersville',
    'Wilmer',
    'Ponder',
    'Boyd',
    'Hickory Creek',
    'Fairview',
    'Hutchins',
    'Hudson Oaks',
    'Ovilla',
    'Celeste',
    'Blue Mound',
    'Double Oak',
    'Leonard',
    'Palmer',
    'Lake Worth',
    'Westworth Village',
    'Kaufman',
    'Mc Kinney',
    'Keene',
    'Paradise',
    'Southlake',
    'Blue Ridge',
    'Cresson',
    'Mansfiel',
    'White Settlement',
  ],
  raleigh: [
    'Raleigh',
    'Cary',
    'Apex',
    'Morrisville',
    'Garner',
    'Wake Forest',
    'Holly Springs',
    'Fuquay-Varina',
    'Knightdale',
    'Rolesville',
    'Wendell',
    'Zebulon',
  ],
  charlotte: [
    'Uptown',
    'South End',
    'North Davidson',
    'Dilworth',
    'Plaza Midwood',
    'Ballantyne',
    'Myers Park',
    'SouthPark',
    'Elizabeth',
    'University City',
    'Eastover',
    'Sedgefield',
    'Wilmore',
    'Montford',
    'Madison Park',
    'Cotswold',
    'Steele Creek',
    'Wesley Heights',
    'Cherry',
    'Fourth Ward',
    'First Ward',
    'Second Ward',
    'Third Ward',
  ],
};
